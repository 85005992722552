var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-con" }, [
    _c("div", { staticClass: "dialog-ruleName" }, [
      _vm._v("规则名称：" + _vm._s(_vm.dataList.ruleName)),
    ]),
    _c("div", { staticClass: "dialog-year" }, [
      _vm._v("年份：" + _vm._s(_vm.dataList.ruleYear)),
    ]),
    _c(
      "div",
      { staticClass: "dialog-table" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.dataList.holidayList, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "holidayName", label: "节日", width: "80" },
            }),
            _c("el-table-column", {
              attrs: { prop: "holidayCount", width: "200", label: "节假日" },
            }),
            _c("el-table-column", {
              attrs: { prop: "holidayDuration", label: "放假天数" },
            }),
            _c("el-table-column", {
              attrs: { prop: "makeupCount", width: "200", label: "补班日" },
            }),
            _c("el-table-column", {
              attrs: { prop: "makeupDuration", label: "补班天数" },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }