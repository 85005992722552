<template>
  <div class="dialog-content">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item label="规则名称" prop="ruleName">
        <el-input
          v-model="ruleForm.ruleName"
          maxlength="20"
          placeholder="请输入规则名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="年份" prop="year">
        <el-date-picker
          v-model="ruleForm.year"
          type="year"
          placeholder="请选择年份"
          @change="changeHoDate"
          value-format="yyyy"
          popper-class="date-picker"
        >
        </el-date-picker>
      </el-form-item>

      <el-row :gutter="48">
        <el-col :span="9">
          <el-form-item label="节假日" required>
            <div
              class="inputRest"
              v-for="(item, index) in ruleForm.holidayTimeList"
              :key="index"
            >
              <el-form-item
                :prop="'holidayTimeList.' + index + '.name'"
                :rules="[
                  { required: true, message: '请输入节假日', trigger: 'blur' },
                  { validator: validatePass, trigger: ['change', 'blur'] },
                ]"
              >
                <el-input
                  @blur="setDateList(item.name)"
                  v-model="item.name"
                  maxlength="10"
                  placeholder="请输入节假日"
                ></el-input>
              </el-form-item>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="放假日期" required>
            <div
              class="inputRest"
              v-for="(item, index) in ruleForm.holidayTimeList"
              :key="index"
            >
              <el-form-item
                :prop="'holidayTimeList.' + index + '.date'"
                :rules="rules.holidayTimeDate"
              >
                <el-date-picker
                  type="dates"
                  placeholder="请选择放假日期"
                  v-model="item.date"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="4" style="padding-left: 0px">
          <div v-for="(item, index) in ruleForm.holidayTimeList" :key="index">
            <i
              class="el-icon-delete"
              @click.prevent="deleteHolidayTimeLine(index)"
              v-show="ruleForm.holidayTimeList.length > 1"
            ></i>
            <i
              class="el-icon-circle-plus-outline"
              v-if="ruleForm.holidayTimeList.length - 1 == index"
              @click="addHolidayTime"
            ></i>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="9">
          <el-form-item label="补班日" prop="supplementary">
            <div
              class="inputRest"
              v-for="(item, index) in ruleForm.supplementary"
              :key="index"
            >
              <el-select v-model="item.value" placeholder="请选择补班日">
                <el-option
                  v-for="(item, index_sub) in suppleList"
                  :key="index_sub"
                  :label="item.label"
                  :value="item.value"
                  @click.native="suppleChanged(index_sub, index)"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="开始时间" prop="supplementTime">
            <div
              class="inputRest"
              v-for="(item, index) in ruleForm.supplementary"
              :key="index"
            >
              <el-date-picker
                type="dates"
                placeholder="请选择开始时间"
                v-model="item.date"
                style="width: 100%"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="4" style="padding-left: 0px">
          <div v-for="(item, index) in ruleForm.supplementary" :key="index">
            <i
              class="el-icon-delete"
              @click.prevent="deleteWorkTimeLine(index)"
              v-show="ruleForm.supplementary.length > 1"
            ></i>
            <i
              class="el-icon-circle-plus-outline"
              v-if="ruleForm.supplementary.length - 1 == index"
              @click="addDomain"
            ></i>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer-container">
      <el-button @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick
        >保存</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  createHolidayList,
  editHolidayList,
} from "@/api/business/holiday/holiday.js";
import moment from "moment";
export default {
  name: "holidayDialog",
  props: {
    modifyList: Array,
  },
  data() {
    return {
      modifyData: this.modifyList[0],
      judgeType: true,
      setDataType: true,
      suppleList: [
        //补班日list
      ],
      paramModifyList: {},
      paramDataList: {
        ruleYear: "",
        ruleName: "",
        holidayList: [],
      },

      ruleForm: {
        ruleName: "",
        year: "",
        holidayTimeList: [{ name: "", date: "", id: null }],
        supplementary: [{ name: "", date: "", id: null }],
      },
      rules: {
        ruleName: [
          { required: true, message: "请输入规则名称", trigger: "blur" },
        ],
        year: [{ required: true, message: "请选择年份", trigger: "change" }],
        holidayTimeDate: [
          { required: true, message: "请选择放假日期", trigger: "blur" },
        ],
      },
      pickerOptions: {
        //根据所选时间筛选可选日期
        disabledDate: (time) => {
          let cur = moment(this.ruleForm.year);
          return (
            time.getTime() < cur.startOf("year").valueOf() ||
            time.getTime() > cur.endOf("year").valueOf()
          );
        },
      },
    };
  },
  created() {
    if (Object.keys(this.modifyData).length > 0) {
      this.paramModifyList = this.modifyData;
      this.judgeType = false;
      this.transferData();
    } else {
      this.paramModifyList = {};
    }
    console.log("数据", this.paramModifyList);
  },
  methods: {
    validatePass(rule, value, callback) {
      let i = 0;
      this.ruleForm.holidayTimeList.forEach((item) => {
        if (value == item.name) {
          i++;
          if (i == 2) {
            i = 0;
            this.setDataType = false;
            callback(new Error("节假日名称重复"));
          } else {
            this.setDataType = true;
          }
        }
      });

      console.log(this.ruleForm.holidayTimeList);
      callback();
    },
    transferData() {
      this.ruleForm.ruleName = this.paramModifyList.ruleName;
      this.ruleForm.year = this.paramModifyList.ruleYear;
      this.ruleForm.holidayTimeList = [];
      this.ruleForm.supplementary = [];
      this.paramModifyList.holidayList.forEach((item, index) => {
        this.suppleList.push({
          //补班日可选下拉列表
          value: item.holidayName,
          label: item.holidayName,
          id: index,
        });
        this.ruleForm.holidayTimeList.push({
          //获取节假日列表
          name: item.holidayName,
          date: item.holidayDate,
          id: index,
        });
        if (item.makeupDate.length > 0) {
          this.ruleForm.supplementary.push({
            //获取补班日列表
            name: item.holidayName,
            value: item.holidayName,
            date: item.makeupDate,
            id: index,
          });
        }
      });
      if (this.ruleForm.supplementary.length == 0) {
        this.ruleForm.supplementary = [{ name: "", date: "", id: null }];
      }
      this.matchList();
    },
    matchList() {
      //数据匹配
      this.ruleForm.supplementary.forEach((sup_item) => {
        this.ruleForm.holidayTimeList.forEach((ho_item) => {
          if (ho_item.name == sup_item.name) {
            ho_item.id = sup_item.id;
          }
        });
      });
      this.ruleForm.supplementary.forEach((item) => {
        this.suppleList.forEach((it_sup, index) => {
          console.log(item, it_sup);
          if (item.value == it_sup.value) {
            item.id = it_sup.id;
            this.suppleList.splice(index, 1);
          }
        });
      });
      let add = this.suppleList.filter(
        //过滤相同id的对象，判断已选补班日跟补班日下拉列表选项
        (item) => !this.ruleForm.supplementary.some((ele) => ele.id === item.id)
      );
      this.suppleList = add;
    },

    deleteHolidayTimeLine(key) {
      //删除节假日行

      this.suppleList.forEach((item, index) => {
        //删除下拉补班日
        if (item.id == this.ruleForm.holidayTimeList[key].id) {
          this.suppleList.splice(index, 1);
        }
      });

      this.ruleForm.supplementary.forEach((item, index) => {
        //删除对应的补班日，如果只剩一条则数据清空
        if (
          item.id == this.ruleForm.holidayTimeList[key].id &&
          item.id != null
        ) {
          if (this.ruleForm.supplementary.length > 1) {
            this.ruleForm.supplementary.splice(index, 1);
          } else {
            this.ruleForm.supplementary = [{ name: "", date: "", id: null }];
          }
        }
      });

      this.ruleForm.holidayTimeList.splice(key, 1);
    },
    deleteWorkTimeLine(index) {
      //删除补班日行
      if (this.ruleForm.supplementary.length > 1) {
        this.ruleForm.supplementary.splice(index, 1);
      }
      this.suppleList = [];
      this.ruleForm.holidayTimeList.forEach((item, index) => {
        if (item.name) {
          this.suppleList.push({
            value: item.name,
            label: item.name,
            id: index,
          });
          item.id = index;
        }
      });
      this.ruleForm.supplementary.forEach((item) => {
        this.suppleList.forEach((it_sup, index) => {
          console.log(item, it_sup);
          if (item.value == it_sup.value) {
            item.id = it_sup.id;
            this.suppleList.splice(index, 1);
          }
        });
      });
    },

    addHolidayTime() {
      this.ruleForm.holidayTimeList.push({
        name: "",
        date: "",
        id: null,
        key: Date.now(),
      });
    },
    addDomain() {
      this.ruleForm.supplementary.push({
        name: "",
        date: "",
        id: null,
        key: Date.now(),
      });
    },
    setDateList(name) {
      //输入框失去焦点输入完成

      if (!name || name.split(" ").join("").length == 0) return;
      if (!this.setDataType) return; //是否有重复节假日

      this.suppleList = [];
      this.ruleForm.holidayTimeList.forEach((item, index) => {
        if (item.name) {
          this.suppleList.push({
            value: item.name,
            label: item.name,
            id: index,
          });
          item.id = index;
        }
      });
      this.ruleForm.supplementary.forEach((item) => {
        this.suppleList.forEach((it_sup, index) => {
          if (it_sup.id == item.id) {
            item.name = it_sup.label;
            item.value = it_sup.value;
            this.suppleList.splice(index, 1);
          }
        });
      });
    },
    suppleChanged(index_sub, index) {
      //补班日select选择index_sub指选择下拉框的下标，index指补班日列的下标

      this.ruleForm.supplementary[index].id = this.suppleList[index_sub].id;
      this.suppleList = [];
      this.ruleForm.holidayTimeList.forEach((item, index) => {
        // if (item.name) {
        this.suppleList.push({
          //补班日可选下拉列表
          value: item.name,
          label: item.name,
          id: index,
        });
        // }
      });
      this.matchList();
    },
    changeHoDate(key) {
      if (!key) {
        this.ruleForm.supplementary.forEach((item) => {
          item.date = "";
        });
        this.ruleForm.holidayTimeList.forEach((item) => {
          item.date = "";
        });
      } else {
        this.ruleForm.holidayTimeList.forEach((sub_item) => {
          if (sub_item.date) {
            let str = key.toString();
            var list = sub_item.date.filter((item) => {
              //节假日过滤不包含年份的
              return item.indexOf(str) != -1; //匹配已包含的年份项内容形成新数组
            });
            sub_item.date = list;
          }
        });
        this.ruleForm.supplementary.forEach((sub_item) => {
          if (sub_item.date) {
            let str = key.toString();
            var list = sub_item.date.filter((item) => {
              //补班日过滤不包含年份的
              return item.indexOf(str) != -1; //匹配已包含的年份项内容形成新数组
            });
            sub_item.date = list;
          }
        });
      }
      console.log(this.ruleForm, key);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.submitList();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitList() {
      let list = [];
      this.ruleForm.holidayTimeList.forEach((item) => {
        if (!item.date) return;
        let dateList = []; //节假日日期list
        item.date.forEach((it_set) => {
          dateList.push(it_set);
        });

        let subList = []; //补班日日期list
        this.ruleForm.supplementary.forEach((sub_item) => {
          if (sub_item.id == item.id && sub_item.value && sub_item != null) {
            if (sub_item.date.length > 0) {
              sub_item.date.forEach((it_base) => {
                subList.push(it_base);
              });
            }
          }
        });
        list.push({
          holidayName: item.name,
          holidayDate: dateList,
          makeupDate: subList,
        });
      });
      this.paramDataList.holidayList = list;
      this.paramDataList.ruleYear = this.ruleForm.year;
      this.paramDataList.ruleName = this.ruleForm.ruleName;
      if (this.judgeType) {
        this.judgeAdd();
      } else {
        this.judgeEdit();
      }
    },
    judgeAdd() {
      //新建节假日
      createHolidayList(this.paramDataList)
        .then((res) => {
          console.log("---------新增节假日成功-----------", res);
          if (res == 1) {
            this.$message({
              message: this.$t("message.saveSuccess"),
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close", "ref");
            }, 1000);
          } else {
            this.$message({
              message: this.$t("message.saveFail"),
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }
        })
        .finally(() => {});
    },
    judgeEdit() {
      //修改编辑节假日
      this.paramDataList["ruleId"] = this.modifyData.ruleId;
      editHolidayList(this.paramDataList)
        .then((res) => {
          console.log("---------修改节假日成功-----------", res);
          if (res == 1) {
            this.$message.success("编辑节假日成功");
            setTimeout(() => {
              this.$emit("close", "ref");
            }, 1000);
          } else {
            this.$message.success("编辑节假日失败,请稍后重试");
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }
        })
        .finally(() => {});
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-content {
  .footer-container {
    padding-bottom: 20px;
    margin: 20px 0;
    text-align: right;
  }

  .el-icon-delete,
  .el-icon-circle-plus-outline {
    font-size: 20px;
    cursor: pointer;
    height: 52px;
    margin-bottom: 12px;
    width: 25px;
    line-height: 104px;
  }
  .inputRest {
    height: 52px;
    margin-bottom: 12px;
  }
}
</style>

<style lang="less">
.date-picker {
  .el-year-table td.today .cell {
    color: #606266;
    font-weight: 700;
  }
  .el-year-table td.today .cell:hover {
    color: #409eff;
    font-weight: 700;
  }
}
</style>