import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 节假日列表查询
 * @param params
 */
export function getHolidayList(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/holiday/page',
    method: 'get',
    params,
  });
}

export function createHolidayList(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/holiday/create',
    method: 'post',
    data: params,
  });
}

export function editHolidayList(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/holiday/edit',
    method: 'post',
    data: params,
  });
}
