<template>
  <div class="dialog-con">
    <div class="dialog-ruleName">规则名称：{{ dataList.ruleName }}</div>
    <div class="dialog-year">年份：{{ dataList.ruleYear }}</div>
    <div class="dialog-table">
      <el-table :data="dataList.holidayList" border style="width: 100%">
        <el-table-column prop="holidayName" label="节日" width="80">
        </el-table-column>
        <el-table-column prop="holidayCount" width="200" label="节假日">
          <!-- <template slot-scope="scope">
            <span>{{ scope.row.startHolidayDate }}</span
            >~ <span>{{ scope.row.endHolidayDate }}</span>
          </template> -->
        </el-table-column>

        <el-table-column prop="holidayDuration" label="放假天数">
        </el-table-column>

        <el-table-column prop="makeupCount" width="200" label="补班日">
          <!-- <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.makeupList" :key="index">
              {{
                (index == scope.row.makeupList.length - 1 &&
                  item.startMakeupDate + "~" + item.endMakeupDate) ||
                item.startMakeupDate + "~" + item.endMakeupDate + ","
              }}
            </div>
          </template> -->
        </el-table-column>

        <el-table-column prop="makeupDuration" label="补班天数">
          <!-- <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.makeupList" :key="index">
              <span>{{ addNumber(item.makeupDuration) }}</span>
            </div>
          </template> -->
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "keepHolidayDialog",
  props: {
    keepList: Array,
  },
  data() {
    return {
      dataList: this.keepList[0],
    };
  },
  created() {
    console.log(this.dataList);
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.dialog-con {
  .dialog-ruleName {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #666666;
  }
  .dialog-year {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #666666;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  .dialog-table {
    margin-bottom: 20px;
  }
}
</style>