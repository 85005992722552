<template>
  <div class="app-container holiday-page">
    <!-- <div class="holiday-title">节假日管理</div>
    <div class="holiday-explan">
      请维护每年的节假日，人员在节假日处于休息状态
    </div> -->
    <el-button type="primary" icon="el-icon-plus" @click="createHoliday"
      >添加节假日</el-button
    >
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 新增&编辑弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="dialogCloseHandler"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <div class="addTitle">{{ dialogTitle }}</div>
      </template>
      <div v-if="dialogType">
        <HolidayDialog
          v-if="dialogVisible"
          @close="dialogCloseHandler"
          :modifyList="modifyData"
        ></HolidayDialog>
      </div>
      <div v-else><KeepHoliday :keepList="setKeepList"></KeepHoliday></div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import HolidayDialog from "./holidayDialog.vue";
import KeepHoliday from "./keepHoliday.vue";
import { getHolidayList } from "@/api/business/holiday/holiday.js";
export default {
  components: {
    finalTable,
    HolidayDialog,
    KeepHoliday,
  },
  data() {
    return {
      setKeepList: [{}], //查看列表数据
      modifyData: [{}], //编辑列表数据
      requestParams: {
        current: 1,
        rowCount: 10,
      },
      dialogTitle: "编辑/添加节假日",
      dialogVisible: false,
      loadingFlag: false,
      dialogType: false,
      dataset: {
        // 是否需要分页
        paginationConfig: {
          need: true,
        },
        // 是否需要查询行
        filterLine: {
          show: false,
        },
        // 行勾选
        selection: {
          need: false,
        },
        header: [
          {
            prop: "sort",
            label: "序号",
            width: "",
          },
          {
            prop: "ruleName",
            label: "名称",
            width: "",
          },
          {
            prop: "ruleYear",
            label: "年份",
            width: "",
          },
          {
            prop: "creationDate",
            label: "创建时间",
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "120",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {},
        // 表格内容配置
        detailConfig: {
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "modify",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "编辑",
              },
            ],
          },
        },
        advanceFilterConfig: {
          deviceName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          productKey: {
            inline: true,
            value: "",
          },
          nodeType: {
            inline: true,
            value: "",
          },
          deviceStatus: {
            inline: true,
            value: "",
          },
          enableStatus: {
            inline: true,
            value: "",
          },
          creationDate: {
            type: "dateRange",
            label: "注册时间",
            value: [],
          },
          activationTime: {
            type: "dateRange",
            label: "激活时间",
            value: [],
          },
          lastOnlineTime: {
            type: "dateRange",
            label: "最后上线时间",
            value: [],
          },
          tagList: {
            type: "tagList",
            label: "标签",
            tagType: "device",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        console.log(val, "xxx-val");
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getDataList();
  },
  methods: {
    getTableList() {},
    tableEventHandler(datas) {
      console.log(datas.type, "-------tableOnclick-----------------");
      if (datas.type === "goSearch") {
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        console.log("datas", datas.params);
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getDataList();
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list.map((item) => item.deviceId);
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "modify":
            this.viewDeviceDetail(datas.row);
            break;
          case "delete":
            this.modifyDeatail(datas.row);
            break;
        }
      }
    },

    viewDeviceDetail(data) {
      //查看
      this.dialogType = false;
      this.dialogTitle = "查看节假日管理";
      this.dialogVisible = true;
      this.setKeepList[0] = data;
    },
    modifyDeatail(data) {
      this.modifyData[0] = data;
      console.log("------编辑-------", this.modifyData[0]);

      this.dialogTitle = "编辑节假日";
      this.dialogVisible = true;
      this.dialogType = true;
    },
    getDataList() {
      this.loadingFlag = true;

      getHolidayList(this.requestParams)
        .then((res) => {
          console.log("-------res-----", res);
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
          this.dataset.tableData.forEach((item, index) => {
            //时间格式化
            item.creationDate = this.common.getTimeMmss(item.creationDate);
            this.$set(item, "sort", index + 1);
          });
          console.log(this.dataset);
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    dialogCloseHandler(refresh) {
      //关闭弹窗
      this.dialogVisible = false;
      if (refresh) {
        this.getDataList();
      }
    },
    createHoliday() {
      //新增节假日
      this.modifyData[0] = {};
      this.dialogTitle = "编辑/添加节假日";
      this.dialogVisible = true;
      this.dialogType = true;
    },
  },
};
</script>

<style  lang="less" scoped>
.holiday-page {
  padding: 43px 45px 100px 30px !important;
  .holiday-title {
    font-size: 24px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #303133;
  }
  .holiday-explan {
    margin-top: 15px;
    margin-bottom: 35px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #999999;
  }
  .addTitle {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #2f3941;
  }
}
</style>